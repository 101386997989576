
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// import DeleteDialogue from '../../../components/DeleteDialogue.vue';
import ZusammenarbeitForm from '../../../components/custom/ZusammenarbeitForm.vue';

export default defineComponent({
  components: {
    // DeleteDialogue,
    ZusammenarbeitForm,
  },
  props: {
    edit: { type: Boolean, default: false },
    userid: { type: Number },
    onSave: { type: Function, required: true },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const units = computed(() => store.getters.getUnits);
    const vorname = ref('');
    const nachname = ref('');
    const unit = ref(-1);
    const login = ref('');
    const email = ref('');

    const role = ref('worker');
    const passwort = ref('');

    const user = ref({});
    const loading = ref(true);

    const unitoptions = computed(() => {
      const options = [];
      for (const unit in units.value) {
        options.push({
          value: units.value[unit].id,
          label: units.value[unit].name,
        });
      }

      return [{ value: -1, label: 'Keine Abteilung' }, ...options];
    });

    const roleoptions = computed(() => {
      if (store.getters.hasPerm('user.manage.all')) {
        return [
          { value: 'gf', label: 'Geschäftsführung' },
          { value: 'gflimited', label: 'CCO' },
          { value: 'manager', label: 'Abteilungsleitung' },
          { value: 'worker', label: 'Mitarbeiter:in' },
        ];
      } else {
        return [
          { value: 'manager', label: 'Abteilungsleitung' },
          { value: 'worker', label: 'Mitarbeiter:in' },
        ];
      }
    });

    store.dispatch('fetchUnits');

    const onSaveClick = () => {
      props.onSave({
        vorname: vorname.value,
        nachname: nachname.value,
        unit_id: unit.value,
        email: email.value,
        role: role.value,
      });
    };

    const name = ref('');
    onMounted(() => {
      if (props.userid) {
        store.dispatch('fetchFeedbackers', props.userid).then((res: any) => {
          feedbacker_selected.value = res.data.message;
        });

        store.dispatch('fetchUserData', props.userid).then((res: any) => {
          user.value = res;
          vorname.value = res.firstname;
          nachname.value = res.lastname;
          unit.value = res.unit_id;

          email.value = res.email;
          role.value = res.roles[0];

          name.value = `${res.firstname} ${res.lastname}`;

          loading.value = false;

          //   store.dispatch('fetchSkillsForUnit', res.unit_id).then((res: any) => {
          //     skills.value = res;
          //   });
        });
      }
    });

    // 360° Feedback

    const feedbacker = computed(() => {
      return store.getters.getWorkers.map((worker: any) => {
        return { value: worker.id, label: `${worker.firstname} ${worker.lastname}` };
      });
    });

    const feedbacker_selected = ref<Array<{ value: number; label: string }>>([]);

    const saveFeedbacker = () => {
      store.dispatch('saveFeedbacker', { id: props.userid, data: feedbacker_selected.value }).then((res: any) => {
        store.dispatch('addNotification', { message: 'Feedbackbefugte gespeichert!' });
      });
    };

    // DELETE Modal
    const deleteModal = ref(false);

    const onDeleteClick = () => {
      deleteModal.value = true;
    };

    const deleteUser = () => {
      deleteModal.value = false;
      store.dispatch('removeWorker', props.userid).then((res: any) => {
        store.dispatch('addNotification', { message: 'Mitarbeiter gelöscht!', lifetime: 5000 });
        router.push({ name: 'MitarbeiterList' });
      });
    };

    const onCloseDeleteModal = () => {
      deleteModal.value = false;
    };

    return {
      user,
      vorname,
      nachname,
      login,
      email,
      role,
      passwort,
      onSaveClick,
      onDeleteClick,
      unit,
      units,
      unitoptions,
      roleoptions,
      loading,
      feedbacker,
      feedbacker_selected,
      saveFeedbacker,
      name,
      deleteModal,
      onCloseDeleteModal,
      deleteUser,
    };
  },
});
