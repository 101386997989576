import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "new" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Headline = _resolveComponent("Headline")!
  const _component_StammdatenForm = _resolveComponent("StammdatenForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Headline, {
      h1: "STAMMDATEN ANLEGEN",
      h2: "Benutzerdaten anlegen"
    }),
    _createVNode(_component_StammdatenForm, { onSave: _ctx.onSave }, null, 8, ["onSave"])
  ]))
}