
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import StammdatenForm from './_StammdatenForm.vue';

export default defineComponent({
  components: {
    StammdatenForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const saving = ref(false);

    const onSave = (user: any) => {
      if (!saving.value) {
        saving.value = true;
        store
          .dispatch('insertWorker', { user })
          .then((res: any) => {
            store.dispatch('addNotification', { message: 'Mitarbeiter:in angelegt' });
            saving.value = false;
            router.push({ name: 'MitarbeiterList' });
          })
          .catch((err: any) => {
            saving.value = false;
            store.dispatch('addNotification', { message: err.response.data.message, type: 'error', lifetime: 5000 });
          });
      }
    };

    return {
      onSave,
    };
  },
});
